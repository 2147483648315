.new-shadow:hover {
  box-shadow: rgba(43, 45, 66, 0.14) 0px 3px 10px;
  /* position: relative; */
}
.select-shadow {
  box-shadow: rgba(43, 45, 66, 0.14) 0px 3px 10px;
}

.card-shadow {
  box-shadow: 1px 1px 4px 0px rgba(169, 178, 207, 1);
}

table {
  border-collapse: separate;
  border-spacing: 0 10px;
  margin-top: -10px; /* correct offset on first border spacing if desired */
}

td {
  padding: 10px;
}
th {
  padding: 10px;
}

td:first-child {
  border-left-style: solid;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
th:first-child {
  border-left-style: solid;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
th:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.profile-shadow {
  box-shadow: 1px 1px 4px 0px rgba(169, 178, 207, 1);
}

.tr {
  box-shadow: 1px 1px 4px 0px rgba(169, 178, 207, 1);
}

@media (max-width: 1080px) {
  .pagination-div {
    flex-direction: column;
  }
}
* {
  font-family: "Roboto";
}
